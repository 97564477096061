












































import KExpansionPanel from '@/@core/components/expansion-panel/KExpansionPanel.vue'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import AdditionalForm from './AdditionalForm.vue'
import CostsForm from './CostsForm.vue'
import GeneralInformationForm from './GeneralInformationForm.vue'
import InvestmentValueForm from './InvestmentValueForm.vue'
import MaterialsForm from './MaterialsForm.vue'
import ProductionValueForm from './ProductionValueForm.vue'

export default defineComponent({
  components: { GeneralInformationForm, KExpansionPanel, InvestmentValueForm, MaterialsForm, CostsForm, ProductionValueForm, AdditionalForm },
    name: 'IndustryForm',
    props: {
        data: {
            type: Object as PropType<any>,
            required: true
        }
    },
    setup() {
        const reRenderCostsCount = ref(0)

        return {
            reRenderCostsCount
        }
    }
})
