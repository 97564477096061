var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"px-1",attrs:{"column":""}},[_c('div',{staticClass:"mx-n7 mb-5"},[_c('v-divider')],1),_c('k-array-input',{attrs:{"label":"Produksi","defaultValue":{
            name: '',
            capacityPerMonth: null,
            pricePerUnit: null,
            productionValue: null,
            marketingArea: ''
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('k-text-input',{attrs:{"label":"Nama Produk"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('k-text-input',{attrs:{"label":"Produksi Per Bulan","type":"number"},on:{"input":function($event){return _vm.updateProductionPerYear(item)}},model:{value:(item.capacityPerMonth),callback:function ($$v) {_vm.$set(item, "capacityPerMonth", _vm._n($$v))},expression:"item.capacityPerMonth"}}),_c('k-text-input',{key:_vm.reRenderCount + '-capacityPerYear',attrs:{"readonly":"","label":"Produksi Per Tahun","type":"number"},model:{value:(item.capacityPerYear),callback:function ($$v) {_vm.$set(item, "capacityPerYear", _vm._n($$v))},expression:"item.capacityPerYear"}}),_c('k-text-input',{attrs:{"label":"Harga Penjualan Per Unit","type":"number"},model:{value:(item.pricePerUnit),callback:function ($$v) {_vm.$set(item, "pricePerUnit", _vm._n($$v))},expression:"item.pricePerUnit"}}),_c('k-text-input',{attrs:{"label":"Nilai Produksi","type":"number"},model:{value:(item.productionValue),callback:function ($$v) {_vm.$set(item, "productionValue", _vm._n($$v))},expression:"item.productionValue"}}),_c('k-combobox-input',{attrs:{"label":"Wilayah Pemasaran","items":_vm.marketingAreaList},model:{value:(item.marketingArea),callback:function ($$v) {_vm.$set(item, "marketingArea", $$v)},expression:"item.marketingArea"}})]}}]),model:{value:(_vm.modelValue.products),callback:function ($$v) {_vm.$set(_vm.modelValue, "products", $$v)},expression:"modelValue.products"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }