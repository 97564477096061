

































import KCurrencyInput from '@/@core/components/input/k-currency-input.vue';
import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import KText from '@/@core/components/typography/KText.vue';
import { useUnitStore } from '@/modules/unit/services/store';
import {
  defineComponent,
  Ref,
  ref,
  PropType
} from '@vue/composition-api';

export default defineComponent({
  components: { KTextInput, KText, KCurrencyInput, KComboboxInput },
  name: 'SingleInvestmentForm',
  props: {
    data: {
      type: Object as PropType<any>,
      required: true
    },
    index: {
      type: Number as PropType<number>,
      required: true
    }
  },
  setup() {
    const reRenderCount: Ref<number> = ref(0);
    const unitStore = useUnitStore();

    const countAcquisitionValue = (item: any) => {
      const hasValue = item?.qty && item?.pricePerUnit;

      if (hasValue)
        item.acquisitionValue = item.qty * item.pricePerUnit;
      else item.acquisitionValue = 0;

      reRenderCount.value++;
    };

    return {
      countAcquisitionValue,
      reRenderCount,
      unitStore
    };
  }
});
