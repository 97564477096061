









































import KTextInput from '@/@core/components/input/KTextInput.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  Ref,
  watchEffect
} from '@vue/composition-api';
import MultipleInvestmentForm from './MultipleInvestmentForm.vue';
import SingleInvestmentForm from './SingleInvestmentForm.vue';
import useCalculateTotalInvestmentValue from '../services/calculateTotalInvestmentValue';
import KCard from '@/@core/components/other/KCard.vue';
import {
  currencyToNumber,
  useNumberToCurrency
} from '@/@core/helpers/formatter';
import KExpansionPanels from '@/@core/components/expansions/k-expansion-panels.vue';
import KExpansionPanel from '@/@core/components/expansion-panel/KExpansionPanel.vue';

export default defineComponent({
  components: {
    KTextInput,
    KText,
    SingleInvestmentForm,
    MultipleInvestmentForm,
    KCard,
    KExpansionPanels,
    KExpansionPanel
  },
  name: 'InvestmentValueForm',
  props: {
    data: {
      type: Object as PropType<any>,
      required: true
    }
  },
  setup(props) {
    const activePanel = ref(null);
    const investmentList = [
      {
        subject: 'Tanah',
        value: 'land'
      },
      {
        subject: 'Bangunan',
        value: 'building'
      },
      {
        subject: 'Mesin dan peralatan',
        isMultiple: true,
        value: 'equipments'
      },
      {
        subject: 'Sarana dan prasarana',
        isMultiple: true,
        value: 'facilities'
      }
    ];

    const calculateTotal = (data: any[]) => {
      if (!Array.isArray(data)) return;

      let total = 0;

      data.map((d) => {
        const increament = currencyToNumber(
          d?.acquisitionValue ?? '0'
        );

        total += increament;
      });

      return useNumberToCurrency(total ?? 0);
    };

    const getTitle = (investmentValue: any) => {
      const subject = investmentValue.subject;
      const hasData = investmentValue.data;
      let total;
      if (hasData) total = calculateTotal(investmentValue.data);
      else total = investmentValue.acquisitionValue;

      return `${subject} --> Rp. ${total}`;
    };

    onMounted(() => {
      const propsData: any = props.data;
      const hasInvestmentValues = propsData.investmentValues?.length;

      if (hasInvestmentValues) return;

      propsData.investmentValues = [];

      investmentList.map((investment, index) => {
        const data = {
          subject: investment.subject,
          value: investment.value,
          qty: null,
          unit: '',
          pricePerUnit: null,
          acquisitionValue: null
        };

        const isMultiple = investment.isMultiple;
        if (isMultiple) {
          propsData.investmentValues.push({
            value: investment.value,
            subject: investment.subject,
            data: [{ ...data, subject: '' }]
          });
        } else propsData.investmentValues.push(data);
      });
    });

    const calculateTotalInvestmentValue =
      useCalculateTotalInvestmentValue(
        (props as any)?.data?.investmentValues
      );
    return {
      investmentList,
      calculateTotalInvestmentValue,
      useNumberToCurrency,
      activePanel,
      calculateTotal,
      getTitle
    };
  }
});
