



























































import { defineComponent, ref } from '@vue/composition-api'
import useModelValue from '@/@core/helpers/modelValue'
import KArrayInput from '@/@core/components/input/KArrayInput.vue'
import KTextInput from '@/@core/components/input/KTextInput.vue'
import KComboboxInput from '@/@core/components/input/KComboboxInput.vue'

export default defineComponent({
    components: { KArrayInput, KTextInput, KComboboxInput },
    name: 'ProductionValueForm',
    setup(props, { emit, attrs }) {
        const reRenderCount = ref(0)
        const modelValue = useModelValue({ emit, attrs })
        const marketingAreaList = [
            {
                text: 'Lokal',
                value: 'Lokal',
            },
            {
                text: 'Regional',
                value: 'Regional',
            },
            {
                text: 'Lokal & Regional',
                value: 'Lokal & Regional',
            },
        ]

        const updateProductionPerYear = (item: any) => {
            item.capacityPerYear = (item.capacityPerMonth || 0) * 12
            reRenderCount.value ++
        }

        return {
            modelValue,
            marketingAreaList,
            updateProductionPerYear,
            reRenderCount
        }
    }
})
