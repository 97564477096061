






























































































































































import KArrayInput from '@/@core/components/input/KArrayInput.vue'
import KComboboxInput from '@/@core/components/input/KComboboxInput.vue'
import KLabel from '@/@core/components/input/KLabel.vue'
import KTextAreaInput from '@/@core/components/input/KTextAreaInput.vue'
import KTextInput from '@/@core/components/input/KTextInput.vue'
import { TableOptions } from '@/@core/helpers/table-options'
import { useClassificationStore } from '@/modules/classification/services/store'
import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'

export default defineComponent({
    components: { KTextInput, KTextAreaInput, KLabel, KComboboxInput, KArrayInput },
    name: 'GeneralInformationForm',
    props: {
        data: {
            type: Object as PropType<any>,
            required: true
        }
    },
    setup(props) {
        const isLoading = ref(false)
        const reRenderCount = ref(0)
        const businessEntityList = [
            {
                text: 'PT',
                value: 'PT',
            },
            {
                text: 'CV',
                value: 'CV',
            },
            {
                text: 'Koperasi',
                value: 'Koperasi',
            },
            {
                text: 'Yayasan',
                value: 'Yayasan',
            },
            {
                text: 'PO',
                value: 'PO',
            },
        ]

        const classificationStore = useClassificationStore()

        const initClassification = async () => {
            await classificationStore.getManyClassification({} as TableOptions)

            // @ts-ignore
            const hasClassification = props.data.classification?.id
            // @ts-ignore
            if (hasClassification) props.data.classification = props.data.classification.fulltext
        }

        const initPermissions = () => {
            // @ts-ignore
            const hasPermissions = props.data.permissions?.length
            if (hasPermissions) {
                // @ts-ignore
                props.data.permissions.map((permission: any) => {
                    permission.date = (permission.date as String).slice(0, 10)
                })
            }
        }

        onMounted(async () => {
            isLoading.value = true
            
            await initClassification()
            initPermissions()

            reRenderCount.value ++
            isLoading.value = false
        })

        return {
            businessEntityList,
            isLoading,
            reRenderCount,
            classificationStore
        }
    }
})
